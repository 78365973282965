import axios from '../../axios/dbase'
import router from '../../router'

export default {
    namespaced: true,
    state: {
        leads: [],
        lead: null,
        leadId: null
    },

    mutations: {
        setLeads(state, lead) {
            state.leads = lead
        },
        setOneLead(state, lead) {
            state.lead = lead
        },
        setLeadId(state, id) {
            state.leadId = id
        },
        addLeads(state, lead) {
            state.leads.push(lead)
        },
        updateLeads(state, id) {
            const idx = state.leads.findIndex(t => t.id === id)
            state.leads.splice(idx, 1)
            state.lead = null
        },
    },


    actions: {
        async createLead({ commit }, payload) {
            try {
                const {data} = await axios.post(`/leads.json`, payload)
                if (data) {
                    commit('setOneLead', {...payload, id: data.name})
                    commit('setLeadId', data.name)
                    router.push('/quiz/' + data.name)
                }
                this._vm.$toast.success("Сделка создана")
            } catch (e) {
                this._vm.$toast.error(e.message)
            }
        },

        async loadLeads({ commit }) {
            try {
                const {data} = await axios.get(`/leads.json`)
                const requests = Object.keys(data).map(id => ({...data[id], id}))
                commit('setLeads', requests)
            } catch (e) {
                this._vm.$toast.error(e.message)
            }
        },

        async loadOneLead({ commit }, id) {
            try {
                const {data} = await axios.get(`/leads/${id}.json`)
                if (data) commit('setOneLead', {...data, id})
            } catch (e) {
                this._vm.$toast.error(e.message)
            }
        },

        async removeLead({ commit }, id) {
            try {
                await axios.delete(`/leads/${id}.json`)

                this._vm.$toast.success("Сделка удалена")
                commit('updateLeads', id)
            } catch (e) {
                this._vm.$toast.error(e.message)
            }
        },

        async updateLead(_, request) {
            try {
                await axios.put(`/leads/${request.id}.json`, request)
                this._vm.$toast.success("Сделка обновлена")
            } catch (e) {
                this._vm.$toast.error(e.message)
            }
        }
    },


    getters: {
        leads(state) {
            return state.leads
        },
        oneLead(state) {
            return state.lead
        },
        leadId(state) {
            return state.leadId
        }
    }
}
