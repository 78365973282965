import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";
import './style/style.scss'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
  beforeMount() {
    return this.$store.dispatch('auth/getUser')
  },
}).$mount('#app')


const options = {
  draggable: false,
  timeout: 2500,
  position: 'top-center'
}

Vue.use(Toast, options);
