import axios from '../../axios/dbase'

export default {
    namespaced: true,
    state: {
        findings: []
    },
    mutations: {
        setFindings(state, findings) {
            state.findings = findings
        }
    },
    actions: {
        async loadFindings({ commit }) {
            try {
                const {data} = await axios.get(`/findings.json`)
                let requests = {}

                Object.keys(data).forEach(key => {
                    requests[key] = data[key].map(item => {
                        item.answer = []
                        return item
                    })
                });
                commit('setFindings', requests)
            } catch (e) {
                this._vm.$toast.error(e.message)
            }
        },


        async updateFindings(_, request) {
            try {
                await axios.put(`/findings.json`, request)
                this._vm.$toast.success("Цены обновлены")
            } catch (e) {
                this._vm.$toast.error(e.message)
            }
        }
    },
    getters: {
        findings: (state) => {
            return state.findings
        },
        findingsCategory: (state) => (id) => {
            return state.findings[id]
        }
    }
}
