<template>
  <div>
    <nav-bar></nav-bar>
    <main>
      <div class="container">
        <router-view/>
      </div>
    </main>
  </div>
</template>


<script>
  import NavBar from "./components/NavBar";

  export default {
    components: {NavBar},
  }
</script>

<style lang="scss">
  main {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
