<template>
    <header class="header">
        <div class="header__block">
            <h1 class="header__block-title">
                <router-link to="/">Quiz по обивке</router-link>
            </h1>
            <nav class="header__block-navbar">
                <ul class="header__navbar-menu">
                    <li v-for="link in links" :key="link.url" class="header__navbar-menu-link">
                        <router-link :to="link.url">{{ link.title }}</router-link>
                    </li>
                    <li v-if="auth" class="header__navbar-menu-link">
                        <a href="#" @click.prevent="onLogout">Выйти</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
    export default {
        computed: {
            auth () {
                return this.$store.getters['auth/isAuthenticated']
            },
            isAdmin() {
                return this.$store.getters['auth/isAdmin']
            },
            links () {
                if (this.isAdmin) {
                    return [
                        {title: 'Сделки', url: '/leads'},
                        {title: 'Квиз', url: '/quiz'},
                        {title: 'Цены', url: '/settings'},
                    ]
                }
                else if (this.auth) {
                    return [
                        {title: 'Квиз', url: '/quiz'},
                    ]
                }
                else {
                    return [
                        {title: 'Войти', url: '/login'},
                    ]
                }
            }
        },
        methods: {
            onLogout () {
                this.$store.commit('auth/logout')
                this.$router.push('/login')
            }
        }
    }
</script>

<style lang="scss">
    @import '../style/colors.scss';

    .header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: $indigo;
        color: #fff;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        padding: 0 20px;
        &__block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-title {
                font-size: 22px;
                font-weight: 500;
                user-select: none;
            }
        }
        &__navbar {
            &-menu {
                display: flex;
                &-link {
                    &>a {
                        display: inline-block;
                        font-size: 20px;
                        font-weight: 500;
                        padding: 20px 15px;
                        background: $indigo;
                        user-select: none;
                        &.active {
                            text-decoration: underline;
                            pointer-events: none;
                        }
                        &:hover {
                            background: lighten($indigo, 5%);
                        }
                    }
                }
            }
        }
    }
</style>
