import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.module'
import findings from './modules/findings.module'
import leads from './modules/leads.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    findings,
    leads
  }
})
