<template>
    <div>
        <div class="not-found">
            <img class="not-found-img" src="404-cat-not-found.gif" alt="Ошибка 404 с котиком">
            <h2 class="not-found-title">Страница не найдена</h2>
            <div class="form-btn">
                <router-link to="/">На главную</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss">
    .not-found {
        text-align: center;
        &-title {
            margin-top: -125px;
            margin-bottom: 20px;
            font-size: 30px;
            font-weight: 600;
        }
    }
</style>
