import axios from 'axios'
import baseAxios from '../../axios/dbase'

import {error} from '../../utils/error'

const TOKEN_KEY = 'jwt-token'
const REFRESH_KEY = 'jwt-refresh-token'
const EXPIRES_KEY = 'jwt-expires'
const USER_KEY = 'user'

export default {
  namespaced: true,
  state: {
      token: localStorage.getItem(TOKEN_KEY),
      refreshToken: localStorage.getItem(REFRESH_KEY),
      expiresDate: new Date(localStorage.getItem(EXPIRES_KEY)),
      user: JSON.parse(localStorage.getItem(USER_KEY)) ?? {},
      userRole: ''
  },


  mutations: {
    setUser(state, user) {
      state.user = user
      state.userRole = user.role === 'admin'
      localStorage.setItem(USER_KEY, JSON.stringify(user.id))
    },

    setToken(state, {refreshToken, idToken, expiresIn = '3600'}) {
      const expiresDate = new Date(new Date().getTime() + Number(expiresIn) * 1000)
      state.token = idToken
      state.refreshToken = refreshToken
      state.expiresDate = expiresDate
      localStorage.setItem(TOKEN_KEY, idToken)
      localStorage.setItem(REFRESH_KEY, refreshToken)
      localStorage.setItem(EXPIRES_KEY, expiresDate.toString())
    },

    logout(state) {
      state.token = null
      state.refreshToken = null
      state.expiresDate = null
      state.user = {}
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem(REFRESH_KEY)
      localStorage.removeItem(EXPIRES_KEY)
      localStorage.removeItem(USER_KEY)
    }
  },


  actions: {
    async login({ commit, dispatch }, payload) {
      try {
        const url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.VUE_APP_FB_KEY}`
        const {data} = await axios.post(url, {...payload, returnSecureToken: true})
        commit('setToken', data)
        await dispatch('getUser', data.localId)
      } catch (e) {
        this._vm.$toast.error(error(e.response.data.error.message));
        throw new Error()
      }
    },

    async refresh({ state, commit }) {
      try {
        const {data} = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${process.env.VUE_APP_FB_KEY}`, {
          grant_type: 'refresh_token',
          refresh_token: state.refreshToken
        })
        commit('setToken', {
          refreshToken: data.refresh_token,
          idToken: data.id_token,
          expiresIn: data.expires_in
        })
      } catch (e) {
        this._vm.$toast.error(error(e.response.data.error.message));
      }
    },

    async getUser({state, commit}, userId) {
      const id = userId ? userId : state.user

      try {
        const {data} = await baseAxios.get(`/users/${id}.json`)
        commit('setUser', {...data, id: id})
      } catch (e) {
        console.error(e.message)
      }
    },
  },


  getters: {
    token(state) {
      return state.token
    },

    isAuthenticated(_, getters) {
      return !!getters.token && !getters.isExpired
    },

    isExpired(state) {
      return new Date() >= state.expiresDate
    },

    isAdmin(state) {
      return state.userRole
    }
  }
}
