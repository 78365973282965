import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'
import AdminGuard from './admin-guard'
import LeadGuard from './lead-guard'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/quiz',
    name: 'Quiz',
    props: true,
    component: () => import('../views/Quiz.vue'),
    children: [
      {
        path: ':id',
        beforeEnter: LeadGuard
      }
    ],
    beforeEnter: AuthGuard
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    beforeEnter: AdminGuard
  },
  {
    path: '/lead/:id',
    name: 'Lead',
    props: true,
    component: () => import('../views/Lead.vue'),
    beforeEnter: AuthGuard
  },
  {
    path: '/leads',
    name: 'Leads',
    component: () => import('../views/Leads.vue'),
    beforeEnter: AdminGuard
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: '/'
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  }, {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

export default router
